<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <ASelect
                    class="mr-2"
                    v-model:value="state.params.is_active"
                    show-search
                    allow-clear
                    placeholder="Pilih Status"
                    style="width: 300px"
                    :options="state.statusList"
                />
                <AInputSearch
                    class="mr-2"
                    v-model:value="state.params.q"
                    placeholder="Cari..."
                    style="width:300px"/>
                <AButton
                    class="mr-2"
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <AButton
                    v-if="hasRoles([ROLE_ADMIN_BK, ROLE_SPC_GROUP, ROLE_SPC, ROLE_CORSALES])"
                    class="mr-3"
                    title="Tambah Pelanggan"
                    type="primary"
                    @click="btnModalShow">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                </AButton>
                <AButton
                    title="Download Excel"
                    type="primary"
                    @click="btnDownloadExcel"
                    :loading="state.download.loading">
                    <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!state.download.loading"></i>
                    <span v-else>Downloading ...</span>
                </AButton>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                row-key="id"
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">
                <template #action="{ record }">
                    <span>
                        <a-tooltip title="Edit">
                            <a-button
                                class="button"
                                size="small"
                                @click="btnEdit(record)">
                                <i class="fe fe-edit" />
                            </a-button>
                        </a-tooltip>
                        <a-tooltip title="Hapus">
                            <a-button
                                class="button"
                                size="small"
                                @click="btnDelete(record.id)">
                                <i class="fe fe-trash" />
                            </a-button>
                        </a-tooltip>
                    </span>
                </template>
            </MdTable>
        </div>

        <!-- reject note -->
        <AModal
            v-model:visible="visibleModal"
            :title="(form.id != null ? 'Edit' : 'Tambah') + ` Pelanggan`"
            width="90%"
            @ok="handleOkModal">
             <template #footer>
                <AButton
                    key="back"
                    @click="handleModalCancel">Batal</AButton>
                <AButton
                    key="submit"
                    type="primary"
                    :loading="form.busy"
                    @click="handleOkModal">{{
                    form.id != null ? 'Perbarui' : 'Simpan'
                }}</AButton>
            </template>

            <AForm
                class="myform"
                :wrapper-col="{ span: 14 }"
                :scroll-to-first-error="true"
                :model="form"
                ref="formRef"
                :rules="state.rules"
                @keydown="form.onKeydown($event)">
                <a-row class="form-row">
                    <a-col :sm="24">
                        <AFormItem
                            label="Nama Lengkap"
                            label-align="left"
                            :label-col="{ sm: { span: 4 } }"
                            :wrapper-col="{ sm: { span: 24 - 4 } }"
                            required
                            name="name"
                            has-feedback>
                            <AInput
                                placeholder="nama lengkap"
                                style="width: 100%"
                                v-model:value="form.name"
                                required />
                        </AFormItem>
                    </a-col>
                </a-row>
                <a-row class="form-row">
                    <a-col :sm="24">
                        <AFormItem
                            label="Deskripsi"
                            label-align="left"
                            :label-col="{ sm: { span: 4 } }"
                            :wrapper-col="{ sm: { span: 24 - 4 } }"
                            required
                            name="description"
                            has-feedback>
                            <ATextarea
                                placeholder="deskripsi"
                                style="width: 100%"
                                v-model:value="form.description"
                                required />
                        </AFormItem>
                    </a-col>
                </a-row>
                <a-row class="form-row">
                    <a-col :sm="24">
                        <AFormItem
                            label="Status"
                            label-align="left"
                            :label-col="{ sm: { span: 4 } }"
                            :wrapper-col="{ sm: { span: 24 - 4 } }"
                            required
                            name="is_active"
                            has-feedback>
                            <ARadioGroup v-model:value="form.is_active">
                                <a-radio :value="1">Aktif</a-radio>
                                <a-radio :value="0">Non Aktif</a-radio>
                            </ARadioGroup>
                        </AFormItem>
                    </a-col>
                </a-row>
            </AForm>

            <div v-if="form.errors.any()">
                <ASpace>
                    <AAlert
                        v-for="(item, index) in form.errors.all()"
                        type="error"
                        :key="index"
                        :message="item.message"
                        banner
                        closable />
                </ASpace>
            </div>
        </AModal>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import { pickBy, includes } from 'lodash'
import Form from 'vform'
import moment from 'moment'
import {
    hasRoles,
    ROLE_ADMIN_BK,
    ROLE_KAM,
    ROLE_SPC,
    ROLE_SPC_GROUP,
    ROLE_CORSALES,
} from '@/helpers'

export default defineComponent({
    setup() {
        const errorMessage = ref(null)
        const formRef = ref('');

        const state = reactive({
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                },
                {
                    title: 'Nama Lengkap',
                    dataIndex: 'name',
                },
                {
                    title: 'Deskripsi',
                    dataIndex: 'description',
                },
                {
                    title: 'Status',
                    dataIndex: 'status_active',
                },
                {
                    title: 'Action',
                    slots: { customRender: 'action' },
                },
            ],
            statusList: [
                {
                    label: "Tidak Aktif",
                    value: 0,
                },
                {
                    label: "Aktif",
                    value: 1,
                },
            ],
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: {
                q: '',
                is_active: null,
                page: 1,
                "per-page": 10,
            },
            download: {
                loading: false,
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: 'Nama tidak boleh kosong',
                    },
                ],
                description: [
                    {
                        required: true,
                        message: 'Deskripsi tidak boleh kosong',
                    },
                ],
                is_active: [
                    {
                        required: true,
                        message: 'Status tidak boleh kosong',
                    },
                ],
            },
        })

        const form = reactive(new Form({
            id: null,
            name: '',
            description: '',
            is_active: 0,
        }))

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get('/api/group-customers', {
                    params: {
                        is_active: state.params.is_active,
                        ...pickBy(state.params),
                    },
                })
                .then(({ data }) => {
                    const { items, _meta } = data
                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // btn edit
        const btnEdit = (item) => {
            form.id = item.id
            form.name = item.name
            form.description = item.description
            form.is_active = item.is_active ? 1 : 0

            visibleModal.value = true
        }

        // btn delete
        const btnDelete = (id) => {
            Modal.confirm({
                title: 'Konfirmasi hapus pelanggan',
                content: 'Apakah anda ingin hapus pelanggan?',
                onOk() {
                    apiClient
                        .delete(`/api/group-customers/${id}`)
                        .then(() => {
                            fetchDataList()
                            message.success('Berhasil dihapus')
                        })
                        .catch(e => message.error('Gagal menghapus!'))
                },
                onCancel() { },
            })
        }

        const btnDownloadExcel = () => {
            state.download.loading = true
            apiClient
                .get('/api/group-customers', {
                    params: {
                        ...state.params,
                        _export: 'xls',
                    },
                    responseType: 'blob',
                    headers: {},
                })
                .then(response => {
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }),
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute(
                        'download',
                        `Master-Data-Group-Customer_${moment().format('DDMMYY_HHmm')}.xlsx`,
                    )
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.download.loading = false
                })
        }

        // handle reject note
        const visibleModal = ref(false);

        const btnModalShow = () => {
            form.reset()
            visibleModal.value = true
        }

        const handleModalCancel = () => {
            visibleModal.value = false;
        };

        const handleOkModal = async () => {

            await formRef.value.validate()


            if (form.id != null) {
                form.put(`/api/group-customers/${form.id}`)
                    .then(response => {
                        if (response === undefined) {
                            errorMessage.value = `Kode error 500, No response from server`
                            return
                        }

                        message.success('Berhasil diperbarui')

                        fetchDataList()
                        form.reset()

                        visibleModal.value = false
                    })
            } else {
                form.post('/api/group-customers')
                    .then(response => {
                        if (response === undefined) {
                            errorMessage.value = `Kode error 500, No response from server`
                            return
                        }

                        message.success('Berhasil ditambahkan')

                        fetchDataList()
                        form.reset()

                        visibleModal.value = false
                    })
            }
        };

        // handle vue
        onMounted(() => {
            fetchDataList()

            // show column action only this roles
            if (!hasRoles([ROLE_ADMIN_BK, ROLE_SPC_GROUP, ROLE_SPC, ROLE_CORSALES])) {
                state.columns = state.columns.filter(item => {
                    if (!includes(['Action'], item.title)) {
                        return item
                    }
                })
            }
        })

        return {
            form,
            formRef,
            visibleModal,
            handleOkModal,
            handleModalCancel,
            handleTableChange,
            btnModalShow,
            btnEdit,
            btnDelete,
            btnDownloadExcel,
            fetchDataList,
            state,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
            ROLE_KAM,
            ROLE_SPC,
            ROLE_SPC_GROUP,
            ROLE_CORSALES,
        }
    },
})
</script>
<style scoped>
.card-body {
    margin-left: 20px;
}

.button {
    margin: 0 3px;
}
</style>
